// Step 4 Add New Chains

import { Chain } from 'config/constants/types'
import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = {
  [Chain.BSC_MAINNET]: ['https://bsc-dataseed.binance.org'],
  [Chain.BSC_TESTNET]: [
    'https://data-seed-prebsc-1-s3.binance.org:8545/',
    'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
    'https://bsc-testnet.blockpi.network/v1/rpc/public',
  ],
  [Chain.AVAX_MAINNET]: ['https://api.avax.network/ext/bc/C/rpc'],
  [Chain.ETH_MAINNET]: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  [Chain.MOONRIVER_MAINNET]: ['https://rpc.api.moonriver.moonbeam.network'],
  [Chain.MOONBEAM_MAINNET]: ['https://rpc.api.moonbeam.network'],
  [Chain.AURORA_MAINNET]: ['https://mainnet.aurora.dev'],
  [Chain.GOERLI]: ['https://rpc.ankr.com/eth_goerli'],
  [Chain.ARBITRUM_MAINNET]: [
    'https://arbitrum-one.publicnode.com',
    'https://rpc.ankr.com/arbitrum',
    'https://arbitrum.blockpi.network/v1/rpc/public',
    'https://arbitrum.rpc.subquery.network/public',
    'https://arbitrum-one.public.blastapi.io',
    'https://arbitrum.llamarpc.com',
    'https://arb1.arbitrum.io/rpc',
    // 'https://open-platform.nodereal.io/f813ed1dea094f60915e5259854c7eee/arbitrum-nitro',
    // 'https://open-platform.nodereal.io/cbc6012a574f480e9dc04f6acb4b4b9d/arbitrum-nitro',
    // 'https://arbitrum-mainnet.core.chainstack.com/4918308dc4f9995b4858361798743a17',
    // 'https://divine-billowing-forest.arbitrum-mainnet.quiknode.pro/9d3ba0307d30af945c4b0fc72b5789f7a82ec840/',
    // 'https://prettiest-polished-shape.arbitrum-mainnet.quiknode.pro/4ce9126b448c38272cc021baade9ac6db37a569f/',
    // 'https://open-platform.nodereal.io/cbc6012a574f480e9dc04f6acb4b4b9d/arbitrum-nitro',
  ],
  [Chain.ARBITRUM_SEPOLIA]: ['https://sepolia-rollup.arbitrum.io/rpc'],
  [Chain.OPTIMISM_MAINNET]: ['https://mainnet.optimism.io'],
  [Chain.NEON_DEVNET]: ['https://proxy.devnet.neonlabs.org/solana'],
  [Chain.OKC_MAINNET]: ['https://exchainrpc.okex.org'],
  [Chain.OMNI_TESTNET]: ['https://testnet.omni.network/'],
  [Chain.ARBITRUM_GOERLI]: ['https://arbitrum-goerli.publicnode.com'],
  [Chain.LINEA_TESTNET]: ['https://rpc.goerli.linea.build'],
  [Chain.POLYGON_MAINNET]: ['https://rpc-mainnet.maticvigil.com'],
  [Chain.POLYGON_AMOY]: ['https://rpc-amoy.polygon.technology'],
  [Chain.ZKSYNC_ERA_MAINNET]: [
    'https://zksync2-mainnet.zksync.io',
    'https://1rpc.io/zksync2-era',
    'https://zksync.drpc.org',
    'https://zksync.meowrpc.com',
    // 'https://soft-quaint-star.zksync-mainnet.quiknode.pro/34a7f166d5ac398f78fc29f9cab0cec2db46c167',
    // 'https://alpha-old-tree.zksync-mainnet.quiknode.pro/28d4794a16705553651381be314ff3bdd5a87fa1',
    // 'https://alien-little-wish.zksync-mainnet.quiknode.pro/89e2d2abc3223d612b9c5f08bebe43f51262a931',
    // 'https://open-platform.nodereal.io/418b38027237409face79c21fea118ff/zksync',
    // 'https://open-platform.nodereal.io/7754cf63738041218c3985fdd36add71/zksync',
    // 'https://rpc.ankr.com/zksync_era/c3cca0aa6d3111068a292f22ea0f234cb7a89507038b01c80d9395d90e5e64ef',
    // 'https://rpc.ankr.com/zksync_era/2c1e67b28a15030c62c1a7f6727b23f6fcba9f514402434161c1ad4b558a9480',
  ],
  [Chain.ZKSYNC_SEPOLIA]: ['https://sepolia.era.zksync.dev'],
  [Chain.MANTLE]: ['https://rpc-tob.mantle.xyz/v1/ZDc4NjJlNTBkYTEyZjc4YzU5OTQ4OGZl'],
  [Chain.HUMANODE_TESTNET]: ['https://explorer-rpc-http.it6.stages.humanode.io'],
  [Chain.RONIN]: ['https://api.roninchain.com/rpc'],
  [Chain.SAIGON]: ['https://saigon-testnet.roninchain.com/rpc'],
  [Chain.HUMANODE_MAINNET]: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
  [Chain.MANTA_MAINNET]: ['https://pacific-rpc.manta.network/http'],
  [Chain.OKX_TESTNET]: ['https://testrpc.x1.tech'],
  [Chain.BASE_SEPOLIA]: ['https://sepolia.base.org'],
  [Chain.BEAM]: ['https://build.onbeam.com/rpc'],
  [Chain.BEAM_TEST]: ['https://subnets.avax.network/beam/testnet/rpc'],
  [Chain.IMMUTABLE]: ['https://rpc.immutable.com	'],
  [Chain.IMMUTABLE_TEST]: ['https://rpc.testnet.immutable.com'],
}

export const prdNodes = {
  [Chain.BSC_MAINNET]: ['https://bsc-dataseed.binance.org'],
  [Chain.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545	'],
  [Chain.AVAX_MAINNET]: ['https://api.avax.network/ext/bc/C/rpc'],
  [Chain.ETH_MAINNET]: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  [Chain.MOONRIVER_MAINNET]: ['https://rpc.api.moonriver.moonbeam.network'],
  [Chain.MOONBEAM_MAINNET]: ['https://rpc.api.moonbeam.network'],
  [Chain.AURORA_MAINNET]: ['https://mainnet.aurora.dev'],
  [Chain.GOERLI]: ['https://rpc.ankr.com/eth_goerli'],
  [Chain.ARBITRUM_MAINNET]: ['https://arb1.arbitrum.io/rpc'],
  [Chain.ARBITRUM_SEPOLIA]: ['https://arbitrum-sepolia.blockpi.network/v1/rpc/public'],
  [Chain.OPTIMISM_MAINNET]: ['https://mainnet.optimism.io'],
  [Chain.NEON_DEVNET]: ['https://proxy.devnet.neonlabs.org/solana'],
  [Chain.OKC_MAINNET]: ['https://exchainrpc.okex.org'],
  [Chain.OMNI_TESTNET]: ['https://testnet.omni.network/'],
  [Chain.ARBITRUM_GOERLI]: ['https://arbitrum-goerli.publicnode.com'],
  [Chain.LINEA_TESTNET]: ['https://rpc.goerli.linea.build'],
  [Chain.POLYGON_MAINNET]: ['https://rpc-mainnet.maticvigil.com'],
  [Chain.ZKSYNC_SEPOLIA]: ['https://sepolia.era.zksync.dev'],
  [Chain.ZKSYNC_ERA_MAINNET]: ['https://zksync2-mainnet.zksync.io'],
  [Chain.MANTLE]: ['https://rpc-tob.mantle.xyz/v1/ZDc4NjJlNTBkYTEyZjc4YzU5OTQ4OGZl'],
  [Chain.HUMANODE_TESTNET]: ['https://explorer-rpc-http.it6.stages.humanode.io'],
  [Chain.RONIN]: ['https://api.roninchain.com/rpc'],
  [Chain.SAIGON]: ['https://saigon-testnet.roninchain.com/rpc'],
  [Chain.HUMANODE_MAINNET]: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
  [Chain.MANTA_MAINNET]: ['https://pacific-rpc.manta.network/http'],
  [Chain.OKX_TESTNET]: ['https://testrpc.x1.tech'],
  [Chain.BASE_SEPOLIA]: ['https://sepolia.base.org'],
  [Chain.BEAM]: ['https://build.onbeam.com/rpc'],
  [Chain.BEAM_TEST]: ['https://build.onbeam.com/rpc/testnet'],
  [Chain.IMMUTABLE]: ['https://rpc.immutable.com	'],
  [Chain.IMMUTABLE_TEST]: ['https://rpc.testnet.immutable.com'],
}

export const aethirNodes = {
  [Chain.BSC_MAINNET]: ['https://bsc-dataseed.binance.org'],
  [Chain.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545	'],
  [Chain.AVAX_MAINNET]: ['https://api.avax.network/ext/bc/C/rpc'],
  [Chain.ETH_MAINNET]: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  [Chain.MOONRIVER_MAINNET]: ['https://rpc.api.moonriver.moonbeam.network'],
  [Chain.MOONBEAM_MAINNET]: ['https://rpc.api.moonbeam.network'],
  [Chain.AURORA_MAINNET]: ['https://mainnet.aurora.dev'],
  [Chain.GOERLI]: ['https://rpc.ankr.com/eth_goerli'],
  [Chain.ARBITRUM_MAINNET]: [
    'https://arbitrum-one.blastapi.io/6564627d-6427-44b1-859d-050bb19ef3e8',
    'https://arbitrum-one.blastapi.io/6564627d-6427-44b1-859d-050bb19ef3e8',
    'https://arbitrum-one.blastapi.io/6564627d-6427-44b1-859d-050bb19ef3e8',
    'https://arbitrum-mainnet.core.chainstack.com/4918308dc4f9995b4858361798743a17',
    'https://open-platform.nodereal.io/5f88deba2dec4512ac607eba0f43c25c/arbitrum-nitro/',
    'https://rpc.ankr.com/arbitrum/2c1e67b28a15030c62c1a7f6727b23f6fcba9f514402434161c1ad4b558a9480',
    'https://arbitrum-mainnet.core.chainstack.com/ee26049f1d0a427bc272997b4920df37',
    'https://open-platform.nodereal.io/d80a850a5d9e41eca43c5ffab44baf6c/arbitrum-nitro',
    'https://arbitrum-mainnet.core.chainstack.com/9a98b9c1ed3b3b527686576523e5467c',
    'https://arbitrum-mainnet.core.chainstack.com/7491c0263206db0a9dd608d195dc8d1a',
    'https://indulgent-alien-scion.arbitrum-mainnet.quiknode.pro/f932e2981bb951db9a4b774fc0d035decd71a177/',
    'https://rpc.ankr.com/arbitrum/01337cff2a51a2de12e719bc978080d54c6532797f5d9c351e49c56268b39990',
    'https://open-platform.nodereal.io/cbc6012a574f480e9dc04f6acb4b4b9d/arbitrum-nitro/',
  ],
  [Chain.ARBITRUM_SEPOLIA]: ['https://arbitrum-sepolia.blockpi.network/v1/rpc/public'],
  [Chain.OPTIMISM_MAINNET]: ['https://mainnet.optimism.io'],
  [Chain.NEON_DEVNET]: ['https://proxy.devnet.neonlabs.org/solana'],
  [Chain.OKC_MAINNET]: ['https://exchainrpc.okex.org'],
  [Chain.OMNI_TESTNET]: ['https://testnet.omni.network/'],
  [Chain.ARBITRUM_GOERLI]: ['https://arbitrum-goerli.publicnode.com'],
  [Chain.LINEA_TESTNET]: ['https://rpc.goerli.linea.build'],
  [Chain.POLYGON_MAINNET]: ['https://rpc-mainnet.maticvigil.com'],
  [Chain.ZKSYNC_SEPOLIA]: ['https://sepolia.era.zksync.dev'],
  [Chain.ZKSYNC_ERA_MAINNET]: ['https://zksync2-mainnet.zksync.io'],
  [Chain.MANTLE]: ['https://rpc-tob.mantle.xyz/v1/ZDc4NjJlNTBkYTEyZjc4YzU5OTQ4OGZl'],
  [Chain.HUMANODE_TESTNET]: ['https://explorer-rpc-http.it6.stages.humanode.io'],
  [Chain.RONIN]: ['https://api.roninchain.com/rpc'],
  [Chain.SAIGON]: ['https://saigon-testnet.roninchain.com/rpc'],
  [Chain.HUMANODE_MAINNET]: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
  [Chain.MANTA_MAINNET]: ['https://pacific-rpc.manta.network/http'],
  [Chain.OKX_TESTNET]: ['https://testrpc.x1.tech'],
  [Chain.BASE_SEPOLIA]: ['https://sepolia.base.org'],
}

const getNodeUrl = (chainId) => {
  if (process.env.REACT_APP_STAGING) {
    return sample([...nodes[chainId]])
  }
  if (process.env.REACT_APP_WHITELABEL_BRAND === 'aethir') {
    return sample([...nodes[chainId]])
  }

  return sample([...nodes[chainId], ...prdNodes[chainId]])
}

export default getNodeUrl
