import {
  darkColorsV2,
  lightColorsV2,
  beamColorsV2,
  carvColorsV2,
  particleColorsV2,
  campusColors,
  moonveilColors,
  ogColors,
} from '../../theme/colorsV2'

export interface FooterTheme {
  text: string
  background: string
}

export const light: FooterTheme = {
  text: lightColorsV2.textSecondary,
  background: lightColorsV2.main2,
}

export const dark: FooterTheme = {
  text: darkColorsV2.textThirdly,
  background: darkColorsV2.main3,
}

export const beam: FooterTheme = {
  text: beamColorsV2.textSecondary,
  background: beamColorsV2.main3,
}

export const carv: FooterTheme = {
  text: carvColorsV2.textSecondary,
  background: carvColorsV2.main2,
}

export const particle: FooterTheme = {
  text: particleColorsV2.textSecondary,
  background: particleColorsV2.main2,
}

export const campus: FooterTheme = {
  text: campusColors.textSecondary,
  background: campusColors.main2,
}

export const moonveil: FooterTheme = {
  text: moonveilColors.textSecondary,
  background: moonveilColors.main2,
}

export const og: FooterTheme = {
  text: ogColors.textSecondary,
  background: ogColors.main2,
}
