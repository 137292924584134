import {
  beamColorsV2,
  darkColorsV2,
  lightColorsV2,
  carvColorsV2,
  particleColorsV2,
  campusColors,
  moonveilColors,
  ogColors,
} from 'uikit/theme/colorsV2'
import { AccordionTheme } from './IFAccordion'
import { alpha } from '@mui/material'

export const light: AccordionTheme = {
  background: lightColorsV2.background,
  headerColor: lightColorsV2.textSecondary,
  headerBackground: lightColorsV2.dark2,
  summaryColor: lightColorsV2.text,
}
export const dark: AccordionTheme = {
  background: darkColorsV2.dark2,
  headerColor: darkColorsV2.textThirdly,
  headerBackground: darkColorsV2.main3,
  summaryColor: darkColorsV2.textThirdly,
}
export const blue: AccordionTheme = {
  background: lightColorsV2.background,
  headerColor: lightColorsV2.dark2,
  headerBackground: lightColorsV2.dark2,
  summaryColor: lightColorsV2.text,
}
export const beam: AccordionTheme = {
  background: beamColorsV2.main3,
  headerColor: beamColorsV2.text,
  headerBackground: beamColorsV2.main,
  summaryColor: beamColorsV2.text,
}

export const carv: AccordionTheme = {
  background: carvColorsV2.main3,
  headerColor: carvColorsV2.text,
  headerBackground: carvColorsV2.main,
  summaryColor: carvColorsV2.text,
}

export const particle: AccordionTheme = {
  background: particleColorsV2.main2,
  headerColor: particleColorsV2.textSecondary,
  headerBackground: alpha(particleColorsV2.dark2, 0.35),
  summaryColor: particleColorsV2.text,
}

export const campus: AccordionTheme = {
  background: campusColors.main3,
  headerColor: campusColors.textSecondary,
  headerBackground: campusColors.background,
  summaryColor: campusColors.text,
}

export const moonveil: AccordionTheme = {
  background: moonveilColors.main3,
  headerColor: moonveilColors.textSecondary,
  headerBackground: moonveilColors.main,
  summaryColor: moonveilColors.text,
}

export const og: AccordionTheme = {
  background: ogColors.main3,
  headerColor: ogColors.textSecondary,
  headerBackground: ogColors.main,
  summaryColor: ogColors.text,
}
